import styled from "styled-components";

export const DatepickerContainer = styled.div`
position: absolute;
right: 0;
top: 0;
z-index: 100;
border: 1px solid #141414;
.rdrDateRangePickerWrapper{
width: 100%;
height: 100%;
}
.apply{
    padding: 10px 20px ;
    font-size: 14px;
    color: #141414;
    position: absolute;
    bottom: 20px;
    left: 20px;
}
`