export const USER_SIGNIN_REQUEST = "USER_SIGNIN_REQUEST";
export const USER_SIGNIN_SUCCESS = "USER_SIGNIN_SUCCESS";
export const USER_SIGNIN_FAIL = "USER_SIGNIN_FAIL";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";

export const USER_SIGNOUT_REQ = "USER_SIGN_OUT_REQ";
export const USER_SIGNOUT_SUCCESS = "USER_SIGN_OUT_SUCCESS";
export const USER_SIGNOUT_FAILED = "USER_SIGNOUT_FAILED"