import styled from "styled-components";

export const SignInContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
background-color:rgba(48,89,105,1);
min-height: 100vh;
;
@media (max-width : 522px){
    padding: 0 10px;
}
`
export const Form = styled.form`
width: 400px;
height: 500px;
border-radius: 8px;
background-color: rgb(237, 246, 229);
padding: 20px;
color: #012443;
h3{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}
p{
    text-align: center;
    color: gray;
}
label{
    display: block;
    font-size: 1.8rem;
    font-weight: 600;
}
.formItem{
    width: 100%;
    padding: 10px 0;
}
.formItem input{
  padding: 10px 10px;
  font: 1.5rem;
  width: 100%;
  outline: none;
  border: 1px solid gray;
  border-radius: 8px;
}
.signinBtn{
    width: 100%;
    border: 3px solid rgba(48,89,105,1);
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2rem;
    color: #012443;
    padding: 10px 0;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;

    :hover{
        border: 3px solid #141414;
        background-color: rgba(48,89,105,1);
        color: rgb(237, 246, 229);
    }

}
.form-error-message{
    color: rgba(236, 95, 95, 1);
}
`