import React, { useState } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Form, SignInContainer } from './styled-signin';
import { signin } from '../../components/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import validate from './../../utils/validateSignin';
export default function SignIn() {
    const userSignIn = useSelector(state => state.userSignIn);
    const { userInfo } = userSignIn;
    const dispatch = useDispatch();
    const [userInputs, setInputs] = useState({
        email: '',
        password: '',
    })
    const [errors, setErrors] = useState({});
    const handleUserInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })

    }
    const submitHandler = async (e) => {
        e.preventDefault();
        setErrors(validate(userInputs))
        if (Object.keys(validate(userInputs)).length === 0) {
            dispatch(signin(userInputs));
            setInputs({
                email: '',
                password: '',
            })
        }

    }

    if (userInfo) {

        return <Redirect to="/home/dashboard" />
    }
    return (
        <SignInContainer>
            <Form>
                <h3>Hi, Welcome!!!</h3>
                <p> Please Enter Your Credentials To Continue</p>

                {userSignIn?.error && <p className="form-error-message">Entered Password or Email is Wrong.</p>}

                <div className="formItem">
                    <label >Email</label>
                    <input type="text" id="input" name="email" placeholder="Enter Email" onChange={handleUserInputs} value={userInputs.email} />
                    <div className="form-error-message">{errors.email}</div>
                </div>
                <div className="formItem">
                    <label>Password</label>
                    <input type="password" id="password" name="password" placeholder="Enter Password" onChange={handleUserInputs} value={userInputs.password} />
                    <div className="form-error-message">{errors.password}</div>
                </div>
                <button className="signinBtn" onClick={submitHandler}>
                    Sign In
                </button>
                {/* <p  style={{margin : '10px 0'}}>Or dont have account ? <Link to="/register"> Register here</Link></p> */}
            </Form>
        </SignInContainer>
    )
}
