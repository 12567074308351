import styled from "styled-components";


export const AnalyticsCont = styled.div`
flex: 5;
position: relative;
overflow-y: auto;
scrollbar-width: none;
::-webkit-scrollbar {
  display: none;
}
`
export const SelectedDate = styled.div`
position: absolute;
top: 20px;
right: 20px;
border-radius: 8px;
padding: 10px;
background-color: #edf2f4;
user-select: none;
cursor: pointer;
`