import React, { useState } from 'react'
import { Form } from './styled-register'
import { RegisterContainer } from './styled-register';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '../../components/actions/userActions';
import { Redirect, useHistory } from 'react-router';
import validate from '../../utils/validateForm';
import { Link } from 'react-router-dom';

export default function Register() {
    const [userInputs, setInputs] = useState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
    })
    const [errors, setErrors] = useState({})
    const userRegister = useSelector(state => state.userRegister);
    const userSignIn = useSelector(state => state.userSignIn);
    const { userInfo } = userSignIn;
    const dispatch = useDispatch();

    const handleUserInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setInputs(prevValue => {
            return {
                ...prevValue,
                [name]: value
            }
        })

    }
    const submitHandler = async (e) => {
        e.preventDefault();
        const errors = validate(userInputs);
        if (Object.keys(errors).length === 0) {
            dispatch(register(userInputs));
            setInputs({
                firstName: '',
                lastName: '',
                email: '',
                password: '',
            })
        } else {
            setErrors(errors)
        }
    }

    if (userInfo) {

        return <Redirect to="/home/dashboard" />
    }
    return (
        <RegisterContainer>
            <Form>
                <h3>Register Here</h3>
                <div className="formItem">
                    <label>First Name :</label>
                    <input type="text" onChange={handleUserInputs} name="firstName" value={userInputs.firstName} />
                    <div className="form-error-message">{errors.firstName}</div>
                </div>
                <div className="formItem">
                    <label>Last Name :</label>
                    <input type="text" onChange={handleUserInputs} name="lastName" value={userInputs.lastName} />
                    <div className="form-error-message">{errors.lastName}</div>
                </div>
                <div className="formItem">
                    <label>Email :</label>
                    <input type="email" onChange={handleUserInputs} name="email" value={userInputs.email} />
                    <div className="form-error-message">{errors.email}</div>
                </div>
                <div className="formItem">
                    <label>Password :</label>
                    <input type="text" onChange={handleUserInputs} name="password" value={userInputs.password} />
                    <div className="form-error-message">{errors.password}</div>
                </div>

                <button className="signupBtn" onClick={submitHandler}>Register</button>
                <h2>or Already have an account ? <Link to="/signin"> Signin </Link></h2>
            </Form>


        </RegisterContainer>
    )
}
