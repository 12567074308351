import './App.css';
import Topbar from './pages/topbar/Topbar';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import SignIn from './pages/sigin/SignIn';
import Register from './pages/register/Register';
import { Content } from './pages/content/Content';
function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path={["/home/dashboard", "/home/stats", "/home/appvault", "/home/calendar", "/home/samhita", "/home/lockscreen"]}><Topbar /><Content /></Route>
          <Route path="/signin"><SignIn /></Route>
          <Route path="/register"><Register /></Route>
        </Switch>
      </Router>

    </div>
  );
}

export default App;
