/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { AnalyticsCont, SelectedDate } from './styled-analytics';
import { DateRange } from '../../components/date-range/DateRange';
import { addDays } from 'date-fns';
import { Graph } from '../../components/graphs/Graph';
import api from '../../endpoint/api';

export default function Analytics() {
    const [loading, setLoading] = useState(false);
    const [isChanged, setIschanged] = useState(false);
    const [earningchart, setEarningChart] = useState([]);
    const [impressionchart, setImpressionChart] = useState([]);
    const [impressionRpmchart, setImpressionRpmChart] = useState([]);
    const [requestChart, setRequestChart] = useState([]);
    const [datepicker, setDatepicker] = useState(false);

    const [date, setDate] = useState([    //for date picker
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ])

    const [dateSelected, setDateSelected] = useState(date); //for current selected date when date picker closed or applied button is clicked 

    const startDate = JSON.stringify(dateSelected[0].startDate.toDateString()).replace(/"/g, " ");
    const endDate = JSON.stringify(dateSelected[0].endDate.toDateString()).replace(/"/g, " ");

    const startyear = new Date(startDate).getFullYear();
    const startmonth = new Date(startDate).getMonth() + 1;
    const startdate = new Date(startDate).getDate();

    const endyear = new Date(endDate).getFullYear();
    const endmonth = new Date(endDate).getMonth() + 1;
    const enddate = new Date(endDate).getDate();

    const start = { startyear, startmonth, startdate }
    const end = { endyear, endmonth, enddate }

    const ObjectMaker = (array = []) => {
        const arrayObject = [];
        array.forEach(arr => {
            arrayObject.push({ name: new Date(arr.cells[0].value).toDateString().slice(4), value: parseFloat(arr.cells[1].value) })
        })
        if (arrayObject.length > 0) return arrayObject;
        else return [{ name: '', value: 0 }]
    }

    const handleLoading = (vale) => {
        setLoading(vale)
    }
    const handleIschanged = (val) => {
        setIschanged(val);
    }

    const handleDatepicker = (val) => {
        setDatepicker(val);
    }
    const handleDate = (val) => {
        setDate(val);
    }
    const handleDateSelected = () => {
        setDateSelected(date)
    }
    async function earningfetch() {
        const res = await api.post("/earnings", { start, end });
        setEarningChart(ObjectMaker(res.data.rows))
    }
    async function impressionfetch() {
        const res = await api.post("/impressions", { start, end });
        setImpressionChart(ObjectMaker(res.data.rows))
    }
    async function impressionRpmfetch() {
        const res = await api.post("/impressionsrpm", { start, end });
        setImpressionRpmChart(ObjectMaker(res.data.rows))
    }
    async function requestfetch() {
        const res = await api.post("/requests", { start, end });
        setRequestChart(ObjectMaker(res.data.rows))
        setLoading(false);
    }
    useEffect(() => {
        handleIschanged(true)
    }, [date])
    useEffect(() => {
        earningfetch()
        impressionfetch()
        impressionRpmfetch()
        requestfetch();
    }, [dateSelected])
    useEffect(() => {
        setLoading(true)
        earningfetch()
        impressionfetch()
        impressionRpmfetch()
        requestfetch();
    }, [])
    return (
        <AnalyticsCont onClick={(e) => { if (datepicker) handleDatepicker(false); handleDate(dateSelected); handleIschanged(false); }}>
            {loading ? <h3>Loading ...</h3> : <React.Fragment>
                {!datepicker && <SelectedDate onClick={() => { handleDatepicker(true) }}>{startDate} - {endDate}</SelectedDate>}
                {datepicker && <DateRange date={date} isChanged={isChanged} handleIschanged={handleIschanged} handleLoading={handleLoading} handleDatepicker={handleDatepicker} handleDate={handleDate} handleDateSelected={handleDateSelected} />}
                {earningchart.length > 0 && <Graph chart={earningchart} title={"Earnings"} name={"USD"} />}
                {impressionchart.length > 0 && <Graph chart={impressionchart} title={"Impressions"} name={" "} />}
                {impressionRpmchart.length > 0 && <Graph chart={impressionRpmchart} title={"Impression RPM"} name={"USD"} />}
                {requestChart.length > 0 && <Graph chart={requestChart} title={" Ad Requests"} name={" "} />}
            </React.Fragment>}

        </AnalyticsCont>
    )
}
