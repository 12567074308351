import Analytics from '../analytics/Analytics'
import SideBar from '../sidebar/SideBar'
import { TheContainer } from './styled-content';
import { Route, Switch, useLocation } from 'react-router-dom'
import Stats from '../stats/Stats';
import StatsXiaomi from '../stats/StatsXiaomi';
import StatsOppoLS from '../stats/StatsOppoLS';
import StatsFinshell from '../stats/StatsFinshell';
import Cookies from 'js-cookie';
import React, { useState, useEffect } from 'react'
import Samhita from '../samhita/Samhita';

export const Content = () => {

  const [src, setSrc] = useState("");
  const [user, setUser] = useState("");

  useEffect(() => {
    getUserInfo();
  }, [src])

  async function getUserInfo() {
    let userInfo;
    await sleep(1000);
    if (Cookies.get("userInfo")) {
      userInfo = JSON.parse(Cookies.get("userInfo"));
      // console.log("Hola user " + userInfo.email);
      if (userInfo.email.includes('xiaomi.com')) {
        setUser("xiaomi");
        setSrc("https://app.appsmith.com/app/xiaomi-revenue-dashboard/login-62dd68861d816b5fddf2ecaa?branch=main&embed=true")
        // setSrc("https://www.thisday.app")
      } else if (userInfo.email.includes('xubijun@oppo.com')) {
        setUser("casablanca");
      } else if (userInfo.email.includes('globalline.club')) {
        setUser("finshell");
      } else {
        setUser("oppo");
        setSrc("https://app.appsmith.com/app/revenue-dashboard/login-62d4478a279768242a2af914?branch=main&embed=true");
      }
    }
  }


  const location = useLocation();


  return (


    // <iframe src={src}
    //   width="100%" height="700vh">
    // </iframe>
    (user === "oppo" ?
      (<TheContainer>
        <SideBar />
        <Switch location={location} key={location.pathname}>
          <Route path="/home/dashboard"><Analytics /></Route>
          <Route path="/home/stats"><Stats /></Route>
        </Switch>
      </TheContainer>)
      : (user === "xiaomi" ?
        (<TheContainer>
          <SideBar oem="xiaomi" />
          <Switch location={location} key={location.pathname}>
            {/* <Route path="/home/dashboard"><Analytics /></Route> */}
            <Route path="/home/appvault"><StatsXiaomi property="appvault" /></Route>
            <Route path="/home/calendar"><StatsXiaomi property="calendar" /></Route>
            <Route path="/home/samhita"><Samhita /></Route>
          </Switch>
        </TheContainer>)
        : (user === "finshell" ?
          ((<TheContainer>
            <SideBar oem="finshell" />
            <Switch location={location} key={location.pathname}>
              {/* <Route path="/home/dashboard"><Analytics /></Route> */}
              <Route path="/home/dashboard"><StatsFinshell /></Route>
            </Switch>
          </TheContainer>)) : (<TheContainer>
            <SideBar oem="oppols" />
            <Switch location={location} key={location.pathname}>
              {/* <Route path="/home/dashboard"><Analytics /></Route> */}
              <Route path="/home/lockscreen"><StatsOppoLS /></Route>
            </Switch>
          </TheContainer>)
        )

      )
    )
  )
}



async function sleep(msec) {
  return new Promise(resolve => setTimeout(resolve, msec));
}
