const validatesignin = (values)=>{
    let errors={}
      //Email Validate check
      if(values.email===''){
          errors.email="Please enter email"
       
       } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)){
          errors.email="Please enter valid email"
       
       }
       //Password valditae check
       if(values.password===''){
         errors.password="Please enter password"
       }
    return errors;   
  }
  export default validatesignin;