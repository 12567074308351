import React from 'react';
import { VscHome } from 'react-icons/vsc';
import { SideBarContainer } from './styled-sidebar';
import { AiOutlineUser } from 'react-icons/ai'
import { Link, useRouteMatch } from 'react-router-dom';
import { ImStatsBars } from 'react-icons/im';
const SideBar = (props) => {
    const route = useRouteMatch();
    React.useEffect(() => {
        const sidebarItems = document.querySelectorAll(".sidebarItem")
        if (route.path === "/home/dashboard") sidebarItems[0].classList.add("active");
        if (route.path === "/home/stats") sidebarItems[1].classList.add("active");
        if (route.path === "/home/appvault") sidebarItems[1].classList.add("active");
        // if (route.path === "/home/lockscreen") sidebarItems[1].classList.add("active");
        if (route.path === "/home/calendar") sidebarItems[2].classList.add("active");
        sidebarItems.forEach(item => {
            item.addEventListener("click", () => {
                sidebarItems.forEach(item => item.classList.remove("active"))
                item.classList.add("active");
            })

        })
    }, [])
    return (
        props.oem === "xiaomi" ?
            <SideBarContainer>
                <h3>Dashboard</h3>
                <ul className="sidebarList">
                    <Link to="/home/samhita">
                        <li className="sidebarItem">
                            <VscHome className="sidebarIcon" /> Samhita
                        </li>
                    </Link>
                    <Link to="/home/appvault">
                        <li className="sidebarItem">
                            <ImStatsBars className="sidebarIcon" /> App Vault
                        </li>
                    </Link>
                    <Link to="/home/calendar">
                        <li className="sidebarItem">
                            <ImStatsBars className="sidebarIcon" /> Calendar
                        </li>
                    </Link>
                </ul>
            </SideBarContainer>
            :

            (props.oem === "oppols" ?
                <SideBarContainer>
                    <h3>Dashboard</h3>
                    <ul className="sidebarList">
                        <Link to="/home/lockscreen">
                            <li className="sidebarItem">
                                <ImStatsBars className="sidebarIcon" /> Stats
                            </li>
                        </Link>
                    </ul>
                </SideBarContainer>
                :
                (props.oem === "finshell" ?

                    <SideBarContainer>
                        <h3>Dashboard</h3>
                        <ul className="sidebarList">
                            <Link to="/home/dashboard">
                                <li className="sidebarItem">
                                    <ImStatsBars className="sidebarIcon" /> Stats
                                </li>
                            </Link>
                        </ul>
                    </SideBarContainer>

                    :

                    <SideBarContainer>
                        <h3>Dashboard</h3>
                        <ul className="sidebarList">
                            <Link to="/home/dashboard">
                                <li className="sidebarItem">
                                    <VscHome className="sidebarIcon" /> Home
                                </li>
                            </Link>
                            <Link to="/home/stats">
                                <li className="sidebarItem">
                                    <ImStatsBars className="sidebarIcon" /> Stats
                                </li>
                            </Link>
                        </ul>
                    </SideBarContainer>
                )
            )
    )
}

export default SideBar
