import styled from "styled-components";

export const RegisterContainer = styled.div`
min-height: 100vh;
background-color: rgba(48,89,105,1);
display: flex;
align-items: center;
justify-content: center;
@media (max-width : 522px){
    padding: 0 10px;
}
`
export const Form = styled.form`
 /* height: calc(100% - 10vh); */
 padding: 20px;
 width: 500px;
 background-color: rgb(237, 246, 229);
 border-radius: 8px;
 color: #012443;

 h3{
    font-size: 2rem;
    font-weight: 600;
    text-align: center;
}
 label{
     display: block;
 }
  
 input[type="text"],input[type="email"]{
     width: 100%;
     font-size: 18px;
     border-radius: 8px;
     border: none;
     outline: none;
     padding: 10px 10px;
 }
 input:focus{
     border: 1px solid  gray;
 }

 .formItem{
  padding: 10px;
  width: 100%;
 }
 
 .signupBtn{
    width: 100%;
    border: 3px solid rgba(48,89,105,1);
    background-color: #fff;
    border-radius: 8px;
    margin-top: 2rem;
    color: #012443;
    padding: 10px 0;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;

    :hover{
        border: 3px solid #141414;
        background-color: rgba(48,89,105,1);
        color: rgb(237, 246, 229);
    }
}
.form-error-message{
    color: rgba(236, 95, 95, 1);
}
h2{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    color: gray;
    margin-top: .8rem;
}
`