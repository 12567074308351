import styled from "styled-components";

export const SideBarContainer = styled.aside`
flex: 1;
background-color: #edf2f4;
top: 84px;
position: sticky;
height: calc(100vh - 84px);
color : #1E3163;
a,a:visited{
    text-decoration: none;
    color: inherit;
}
h3{
    text-align: start;
    font-weight: 600;
    color: #141414;
    font-size: 1rem;
    margin-top: 1rem;
    padding-left: .5rem;
}
.sidebarList{
    padding: 10px 5px
}
.sidebarItem{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 10px 10px 15px;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    
    :hover{
        background-color: #D4ECDD;
    }
    
}
.sidebarIcon{
    margin-right: .5rem;
}
.active{
    background-color: #D4ECDD;
}
`