// import Cookies from "js-cookie";
import { combineReducers,createStore,applyMiddleware,compose} from "redux";
import thunk from "redux-thunk";
import { userRegisterReducer, userSignInReducer } from "./components/reducers/userReducers";
import Cookie from 'js-cookie';

const userInfo = Cookie.get("userInfo") || null;
const userReg = null;
const initialstate = {userSignIn : {userInfo},userRegister : {userReg}}
const reducer = combineReducers({
    userSignIn : userSignInReducer,
    userRegister : userRegisterReducer,
})
const store = createStore(reducer,initialstate,compose(applyMiddleware(thunk),
window.devToolsExtension ? window.devToolsExtension() : f => f
))
export default store;