/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { addDays } from 'date-fns';
import { StatsCont } from './styled-stats';
import { SelectedDate } from '../analytics/styled-analytics';
import { DateRange } from '../../components/date-range/DateRange';
import numberWithCommas from '../../utils/numberWithCommas';
import api from '../../endpoint/api';
const Stats = (props) => {
    const [loading, setLoading] = useState(false);
    const [isChanged, setIschanged] = useState(false);
    const [earningchart, setEarningChart] = useState([]);
    const [impressionchart, setImpressionChart] = useState([]);
    const [impressionRpmchart, setImpressionRpmChart] = useState([]);
    const [requestChart, setRequestChart] = useState([]);
    const [datepicker, setDatepicker] = useState(false);
    const [sum, setStum] = useState({ earnings: 0, impressions: 0, impressions_rpm: 0, requests: 0 })

    const [date, setDate] = useState([    //for date picker
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    
    const [dateSelected, setDateSelected] = useState(date);
    const ObjectMaker = (array = []) => {
        const arrayObject = [];
        array.forEach(arr => {
            arrayObject.push({ name: new Date(arr.cells[0].value).toDateString().slice(4), value: parseFloat(arr.cells[1].value) })
        })
        if (arrayObject.length > 0) return arrayObject;
        else return [{ name: '', value: 0 }]
    }
    const startDate = JSON.stringify(dateSelected[0].startDate.toDateString()).replace(/"/g, " ");
    const endDate = JSON.stringify(dateSelected[0].endDate.toDateString()).replace(/"/g, " ");

    const startyear = new Date(startDate).getFullYear();
    const startmonth = new Date(startDate).getMonth() + 1;
    const startdate = new Date(startDate).getDate();

    const endyear = new Date(endDate).getFullYear();
    const endmonth = new Date(endDate).getMonth() + 1;
    const enddate = new Date(endDate).getDate();

    const start = { startyear, startmonth, startdate }
    const end = { endyear, endmonth, enddate }
    const handleLoading = (vale) => {
        setLoading(vale)
    }
    const handleIschanged = (val) => {
        setIschanged(val);
    }

    const handleDatepicker = (val) => {
        setDatepicker(val);
    }
    const handleDate = (val) => {
        setDate(val);
    }
    const handleDateSelected = () => {
        setDateSelected(date)
    }
    async function earningfetch() {
        const res = await api.post("/earningsxiaomi", { start, end, "property": props.property });
        setEarningChart(ObjectMaker(res.data.rows))
        setStum(prev => ({ ...prev, earnings: res.data.totals.cells[1].value }))
    }
    async function impressionfetch() {
        const res = await api.post("/impressionsxiaomi", { start, end, "property": props.property });
        setImpressionChart(ObjectMaker(res.data.rows))
        setStum(prev => ({ ...prev, impressions: res.data.totals.cells[1].value }))
    }
    async function impressionRpmfetch() {
        const res = await api.post("/impressionsrpmxiaomi", { start, end, "property": props.property });
        setImpressionRpmChart(ObjectMaker(res.data.rows))
        setStum(prev => ({ ...prev, impressions_rpm: res.data.totals.cells[1].value }))
    }
    async function requestfetch() {
        const res = await api.post("/requestsxiaomi", { start, end, "property": props.property });
        setRequestChart(ObjectMaker(res.data.rows))
        setStum(prev => ({ ...prev, requests: res.data.totals.cells[1].value }))
        setLoading(false);
    }
    useEffect(() => {
        handleIschanged(true)
    }, [date])
    useEffect(() => {
        earningfetch()
        impressionfetch()
        impressionRpmfetch()
        requestfetch();
    }, [dateSelected])
    useEffect(() => {
        setLoading(true)
        earningfetch()
        impressionfetch()
        impressionRpmfetch()
        requestfetch();
    }, [])
    let tableData = []

    if (requestChart.length > 0) {
        for (var i = 0; i < earningchart.length; i++) {
            tableData[i] = {
                date: earningchart[i]?.name || 0, earning: numberWithCommas(earningchart[i]?.value || 0), impression: numberWithCommas(impressionchart[i]?.value || 0), impressionRpm: numberWithCommas(((earningchart[i]?.value * 1000) / impressionchart[i]?.value) || 0), request: numberWithCommas(requestChart[i]?.value || 0)
            }

        }
    }

    return (
        <StatsCont onClick={(e) => { if (datepicker) handleDatepicker(false); handleDate(dateSelected); handleIschanged(false); }}>
            {loading ? <h3>Loading ...</h3> : <React.Fragment>
                {!datepicker && <SelectedDate onClick={() => { handleDatepicker(true) }}>{startDate} - {endDate}</SelectedDate>}
                {datepicker && <DateRange date={date} isChanged={isChanged} handleIschanged={handleIschanged} handleLoading={handleLoading} handleDatepicker={handleDatepicker} handleDate={handleDate} handleDateSelected={handleDateSelected} />}
                {requestChart.length > 0 && <table>
                    <thead><tr>
                        <th><span style={{ 'font-weight': 'bold' }}>Date</span></th>
                        <th><span style={{ 'font-weight': 'bold' }}>Requests</span></th>
                        <th><span style={{ 'font-weight': 'bold' }}>Impressions</span></th>
                        <th><span style={{ 'font-weight': 'bold' }}>Impression RPM ($)</span></th>
                        <th><span style={{ 'font-weight': 'bold' }}>Estimated Earnings ($)</span></th>
                    </tr></thead>
                    <tbody>
                        {tableData.map((data, indx) => {
                            return <tr key={indx}><td>{data.date}</td>
                                <td>{data.request}</td>
                                <td>{data.impression}</td>
                                <td>{data.impressionRpm}</td>
                                <td>{data.earning}</td></tr>
                        })}
                        <tr><td><span style={{ 'font-weight': 'bold' }}>Total</span></td>
                            <td><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(sum.requests))}</span></td>
                            <td><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(sum.impressions))}</span></td>
                            <td><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(sum.impressions_rpm))}</span></td>
                            <td><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(sum.earnings))}</span></td></tr>
                    </tbody>

                </table>}
            </React.Fragment>}
        </StatsCont >
    )
}

export default Stats
