import React, { useState } from 'react';
import { MainContainer } from './styled-topbar';
import { VscSignIn, VscSignOut } from 'react-icons/vsc';
import { useSelector, useDispatch } from 'react-redux';
import { defaultUser, reportLogo } from '../../assets/imageurls/img';
import { Link, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie';
import { signout } from '../../components/actions/userActions';
export default function Topbar() {
    const userSignIn = useSelector(state => state.userSignIn);
    const dispatch = useDispatch();

    let { userInfo } = userSignIn;


    // let src = userInfo ? dummyUser : defaultUser;
    let src = defaultUser;
    const [showMenu, setMenu] = useState(false);



    if (userInfo) {
        userInfo = userInfo.toString();
        userInfo = JSON.parse(userInfo)

    }
    if (!userInfo) {
        return <Redirect to="/signin" />;
    }
    const handleClick = (e) => {
        Cookies.remove("userInfo");
        if (!Cookies.get("userInfo")) {
            dispatch(signout())
        }
    }

    return (
        <MainContainer showMenu={showMenu}>
            {/* <p className="admin">{userInfo?.data?.firstName || userInfo?.firstName} {userInfo?.data?.lastName || userInfo?.lastName}</p> */}
            <p className="admin">
                <img src={reportLogo} height='69px' width='100px'></img>
            </p>
            <div className="user">
                <img src={src} alt="" className="userPic" onClick={() => setMenu(!showMenu)} />
            </div>

            <div className="userMenu">
                <ul>
                    {userInfo ? <li onClick={handleClick}><Link to="/signin"><VscSignOut /> Sign Out</Link></li> : <li><Link to="/signin"><VscSignIn /> Sign In</Link></li>}
                </ul>
            </div>
        </MainContainer>
    )
}
