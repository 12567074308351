import styled from "styled-components";



export const GraphContainer = styled.div`
width: 100%;
height: 300px;
margin-top: 100px;
h3{
    position: relative;
    left: 80px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 24px;
    display: inline-block;
    color:  rgba(48,89,105,1);
}
`
export const TooltipCustom = styled.div`
background-color: #fff;
border-radius: 8px;
padding: 8px;
color: #141414;
box-shadow: 0px 0px 5px rgba(0,0,0,0.5) ;
.label{
    font-weight: 600;
    font-size: 1rem;
}
.stats{
    font-size: 1.2rem;
}
`