import styled from "styled-components";


export const MainContainer = styled.nav`
top: 0;
display: flex;
align-items: center;
justify-content: space-between;
padding-left: 20px;
padding-right: 20px;
position: sticky;
background-color: rgba(48,89,105,1);
color: #fff;
z-index: 9999;
.admin{
    font-size: 2rem;
    font-weight: 600;
    user-select: none;
}
.user{    
    margin-right : .7rem;  
    cursor: pointer; 
}
.userPic{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    
}
.userMenu{
    position: absolute;
    right: 0;
    top: 100%;
    width: 10rem;
    /* display : ${props => props.showMenu ? "block" : "none"};    */
    background-color: #EFEFEF;
    transform: scale(${props => props.showMenu ? 1 : 0});
    transform-origin: top center;
    transition: transform .15s ease;
    padding: 20px;
    border-radius: 8px;
    
    ::after{
         content: '';
         position: absolute;
         height: 50px;
         width: 50px;
         top: 0;
         left: 50%;
         background-color: #EFEFEF;
         transform: rotate(45deg);
         z-index : -1;
    }

    ul li  {
        list-style: none;
        text-align: center;
        user-select: none;
        cursor: pointer;
        border-radius: 8px;
        padding: 10px 5px;
        :hover{
            background-color: #9D9D9D;
        }
    }
    ul li a{
        text-decoration: none;
        color: #141414;
    }
}
`