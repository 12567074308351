import { USER_REGISTER_FAIL, USER_REGISTER_REQUEST, USER_REGISTER_SUCCESS, USER_SIGNIN_FAIL, USER_SIGNIN_REQUEST, USER_SIGNIN_SUCCESS, USER_SIGNOUT_REQ, USER_SIGNOUT_SUCCESS } from "../constants/userConstants";


export const userSignInReducer = function(state={},action){
     
     switch(action.type){
       
        case USER_SIGNIN_REQUEST : return {loading : true}
        case USER_SIGNIN_SUCCESS : return {loading : false , userInfo : action.payload}
        case USER_SIGNIN_FAIL : return {loading : false , error : action.payload }
        case USER_SIGNOUT_REQ : return {loading : true}
        case USER_SIGNOUT_SUCCESS : return {loading : false,userInfo : action.payload}
        default : return state
     }

}

export const userRegisterReducer = function(state={},action){

switch(action.type){
  case USER_REGISTER_REQUEST : return {loading : true}
  case USER_REGISTER_SUCCESS : return {loading : false,userReg : action.payload}
  case USER_REGISTER_FAIL : return {loading : false,error : action.payload}
  default : return state

}

}