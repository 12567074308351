import React from 'react'
import { GraphContainer, TooltipCustom } from './styled-graph'
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import numberWithCommas from '../../utils/numberWithCommas';


const CustomTooltip = ({ active, payload,label}) => {
  
  if (active && payload && payload.length) {
    let name = payload[0].name === " "  ? true : false 
    return (
      <TooltipCustom>
        <p className="label">{label}</p>
        {name ? <p className="stats">{numberWithCommas(payload[0].value)}</p> : <p className="stats">{`${payload[0].name} : ${numberWithCommas(payload[0].value)}`}</p>}
      </TooltipCustom>
    );
  }

  return null;
}
export const Graph = (props) => {
    const {chart,title,name} = props;
    return (
 <GraphContainer>
 <h3>{title}</h3>
<ResponsiveContainer width="100%" height="100%">
        <LineChart
          width={500}
          height={300}
          data={chart}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid vertical={false}/>
          <XAxis dataKey={"name"}/>
          <YAxis tickCount={5}  tickFormatter={tick => numberWithCommas(tick)} />
          <Tooltip content={<CustomTooltip />} />
          <Line type="linear" name={name} dataKey="value"  stroke=" rgba(48,89,105,1)" activeDot={{stroke: '#5ba56e',fill : "#0D98BA", r: 6}}  strokeWidth={2}/>
        </LineChart>
      </ResponsiveContainer>
 </GraphContainer>
    )
}
