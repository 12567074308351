import styled from "styled-components";

export const StatsCont = styled.div`
flex: 5;
position: relative;
overflow-y: auto;
scrollbar-width: none;
display: flex;
/* align-items: center; */
justify-content: center;
display: block;
::-webkit-scrollbar {
  display: none;
}
table{
    margin: 100px;
    border: 1px solid gray;
    border-spacing: 0;
}
thead{
    text-align: center;
}
table th{
    padding: 10px;
    font-weight: 500;
    font-size: 1.3rem;
    border-bottom: 1px solid gray;
}
tbody{
    text-align: center;
}
tbody tr{
    border-top: 1px solid #141414;
}
tbody tr td{
    padding: 15px ;
   
}
tbody tr:not(:last-child) td{
 
    border-bottom: 1px solid gray;
}
`