const validate = (values)=>{
    let errors={}
      //Firstname Validate check
      if(values.firstName===''){
         errors.firstName="Please enter first name"
      }
      //Last name validate check
      if(values.lastName === ''){
          errors.lastName = "Please enter last name"
      }
      //Email Validate check
      if(values.email===''){
          errors.email="Please enter email"
       
       } else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.email)){
          errors.email="Please enter valid email"
       
       }
       //Password valditae check
       if(values.password===''){
         errors.password="Please enter password"
       
       } else if(values.password.length < 8){
           errors.password = "Enter password more than 8"
     
       } else if(!/^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(values.password)){
           errors.password = "Enter password with atleast a speacial character and a digit";
        
       }
    return errors;   
  }
  export default validate;