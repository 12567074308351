import React from 'react'
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';

import { DatepickerContainer } from './styled-date-range';

export const DateRange = (props) => {
    const {handleDatepicker,handleDate,date,handleDateSelected,handleLoading,handleIschanged,isChanged} = props;
    return (
        <DatepickerContainer onClick={(e)=>e.stopPropagation()}>
        <DateRangePicker
        onChange={item => handleDate([item.selection])}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={2}
        ranges={date}
        maxDate={new Date()}
        rangedCalendars={true}
        direction="horizontal"
        />
        <button className="apply" disabled={!isChanged} onClick={(e)=>{e.stopPropagation();handleDatepicker(false);handleDateSelected();handleLoading(true);handleIschanged(false);}}>Apply</button>
    </DatepickerContainer>
    )
}
