import axios from "axios";
import React, { useEffect, useState } from "react"
import { DateRange } from "../../components/date-range/DateRange";
import numberWithCommas from "../../utils/numberWithCommas";
import { addDays } from 'date-fns';
import { SelectedDate } from "../analytics/styled-analytics";
import moment from 'moment'
import { StatsCont } from "../stats/styled-stats";


const Samhita  = () =>{
    const [loading, setLoading] = useState(false);
    const [total,setTotal] = useState({
        total_pageviews : 0,
        total_adclicks : 0,
        total_adrpm : 0,
        total_adcpc : 0,
        total_net_to_ekank : 0,
        total_xiaomi_revenue : 0
    });
    const [totalAdSense,setTotalAdSense] = useState({
        total_sum_of_ad_impression : 0,
        total_sum_of_estimated_gross_revenue : 0,
        total_sum_of_net_revenue_to_ekank : 0,
        total_sum_of_xiaomi_share : 0,
        total_ecpm : 0
    })
    const [tab,setTab] = useState(0);
    const [data,setData] = useState()
    const [tableData,setTableData]  = useState([]);
    const [isChanged, setIschanged] = useState(false);
    const [datepicker, setDatepicker] = useState(false);
    const [date, setDate] = useState([    //for date picker
        {
            startDate: addDays(new Date(), -7),
            endDate: new Date(),
            key: 'selection'
        }
    ])
    const [dateSelected, setDateSelected] = useState(date); //1
    const [adSenseData,setAdSenseData] = useState([]);
    const [adSenseTableData,setAdSenseTableData] = useState([]);

    const startDate = JSON.stringify(dateSelected[0].startDate.toDateString()).replace(/"/g, " ");
    const endDate = JSON.stringify(dateSelected[0].endDate.toDateString()).replace(/"/g, " ");

    const startyear = new Date(startDate).getFullYear();
    const startmonth = new Date(startDate).getMonth() + 1;
    const startdate = new Date(startDate).getDate();

    const endyear = new Date(endDate).getFullYear();
    const endmonth = new Date(endDate).getMonth() + 1;
    const enddate = new Date(endDate).getDate();

    const start = { startyear, startmonth, startdate }
    const end = { endyear, endmonth, enddate }

    const handleDateSelected = () => {
        setDateSelected(date)
    }

    const handleIschanged = (val) => {
        setIschanged(val);
    }

    const handleDatepicker = (val) => {
        setDatepicker(val);
    }

    const handleDate = (val) => {
        setDate(val);
    }

    const handleLoading = (vale) => {
        setLoading(vale)
    }
    console.log('dateSelected',dateSelected);


    const makeTableTaboola = () => { 
        let array = [];
        // console.log(data);
        let sum_pageview = 0,sum_adclicks=0, sum_adrpm =0,sum_adcpc=0,sum_net_to_ekank=0,sum_xiaomi_revenue=0;
        data?.results?.forEach((element)=>{
            array.push(
                {
                    date : new Date(element.date).toDateString().slice(4),
                    pageView : numberWithCommas(element.page_views),
                    adClicks : numberWithCommas(element.clicks),
                    rpm : numberWithCommas(element.ad_rpm),
                    ad_cpc : numberWithCommas(element.ad_cpc),
                    net_to_ekank : numberWithCommas(element.ad_revenue),
                    xiaomi_revenue : numberWithCommas(element.ad_revenue*0.7)
                }
            )
            sum_pageview+=element.page_views;
            sum_adclicks+=element.clicks;
            sum_net_to_ekank+= element.ad_revenue;
            sum_xiaomi_revenue+=(element.ad_revenue*0.7);
            sum_adcpc=sum_net_to_ekank/sum_adclicks;
            sum_adrpm=(sum_net_to_ekank*1000)/sum_pageview;
            
        });
        setTableData((prev)=>{return array});
        setTotal({
            total_pageviews : sum_pageview,
            total_adclicks : sum_adclicks,
            total_adrpm : sum_adrpm,
            total_adcpc : sum_adcpc,
            total_net_to_ekank : sum_net_to_ekank,
            total_xiaomi_revenue : sum_xiaomi_revenue
        })
    }

    useEffect(() => {
        handleIschanged(true)
    }, [date])

    const makeTableAdSense = () => {
        let array = [];
        let sum_of_ad_impression = 0, sum_of_estimated_gross_revenue=0,sum_of_net_revenue_to_ekank=0,sum_xiaomi_revenue=0,ecpm=0;
        adSenseData.forEach((element,index)=>{
            if(moment(element[1]).isSameOrAfter((`${start.startyear}-${start.startmonth}-${start.startdate}`)) && moment(element[1]).isSameOrBefore(`${end.endyear}-${end.endmonth}-${end.enddate}`)){
                array.push({
                    date : new Date(element[1]).toDateString().slice(4),
                    sum_of_ad_impression : element[2],
                    sum_of_estimated_gross_revenue : element[3],
                    sum_of_net_revenue_to_ekank : element[4],
                    sum_of_xiaomi_share : element[5],
                    ecpm : element[6]
                })
                sum_of_ad_impression+=parseInt(element[2]);
                sum_of_estimated_gross_revenue+=parseFloat(element[3].substr(2).replaceAll(',',''));
                sum_of_net_revenue_to_ekank+=parseFloat(element[4].substr(2).replaceAll(',',''));
                sum_xiaomi_revenue+=parseFloat(element[5].substr(2).replaceAll(',',''))
                ecpm=((sum_of_estimated_gross_revenue*1000)/sum_of_ad_impression).toFixed(2)
            }
        });
        console.log('array',array);
        setAdSenseTableData(array);
        setTotalAdSense({
            total_sum_of_ad_impression : sum_of_ad_impression,
            total_sum_of_estimated_gross_revenue : sum_of_estimated_gross_revenue,
            total_sum_of_net_revenue_to_ekank : sum_of_net_revenue_to_ekank,
            total_sum_of_xiaomi_share : sum_xiaomi_revenue,
            total_ecpm : ecpm
        })
    }

    useEffect(()=>{
        if(tab===0){
            const getData = async()=> {
                console.log(start,'',end);
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");
                var raw = JSON.stringify({"start":{"startdate":`${start.startyear}-${start.startmonth}-${start.startdate}`},"end":{"enddate":`${end.endyear}-${end.endmonth}-${end.enddate}`}});
                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: raw,
                    redirect: 'follow'
                }
                
                fetch("https://reports.thisday.app/taboolasamhita", requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        console.log(result);setData(result)})
                    .catch(error => console.log('error', error));
            };
            getData();
            makeTableTaboola();
        }
        else{
            const getData = async() => {
                const res = await axios.get('https://sheets.googleapis.com/v4/spreadsheets/1gYHIVXeUpA6SijwQiRTLnLz5ECXFEQwboxPbCeoVrCw/values/Google Ads Pivot?key=AIzaSyDL9jRJr-DrJyZYdLReyY6fyj7FMKmB8ak');
                console.log(res.data.values);
                setAdSenseData(res.data.values);
            }
            getData();
            makeTableAdSense();            
        }
    },[dateSelected,tab]);

    useEffect(()=>{
        if(tab===0){
            makeTableTaboola();
        }
        else{
            makeTableAdSense()
        }
    },[data,tab])

    console.log(tableData);

    return (
        // <React.Fragment>
        <StatsCont onClick={(e) => { if (datepicker) handleDatepicker(false); handleDate(dateSelected); handleIschanged(false); }}>
            <div style={{width:'100%',padding:'50px'}}>
                
            {!datepicker && <SelectedDate onClick={() => { handleDatepicker(true) }}>{startDate} - {endDate}</SelectedDate>}
            {datepicker && <DateRange date={date} isChanged={isChanged} handleIschanged={handleIschanged} handleLoading={handleLoading} handleDatepicker={handleDatepicker} handleDate={handleDate} handleDateSelected={handleDateSelected} />}

                <ul style={{display:'flex',listStyle:'none',width:'200px',justifyContent:'space-around'}}>
                    <li style={{borderBottom:tab===0 && '3px solid black',cursor:'pointer'}} onClick={()=>setTab(0)}>
                        <h3>Taboola</h3>
                    </li>

                    <li style={{borderBottom:tab===1 && '3px solid black',cursor:'pointer'}} onClick={()=>setTab(1)}>
                        <h3>AdSense</h3>
                    </li>
                </ul>
                <div style={{width : '100%', height :'auto' , display:'flex',justifyContent:'center'}}>
                    {tab===0 && <table style={{width : '100%'}}>
                        <thead ><tr style={{alignItems:'center',height:'60px', width:'100%', display:'flex',justifyContent:'space-around'}}>
                            <th style={{width:'14%',borderBottom:'none',}}><span style={{ fontSize:'16px','font-weight': 'bold' }}>Date</span></th>
                            <th style={{width:'14%',borderBottom:'none'}}><span style={{ fontSize:'16px', 'font-weight': 'bold' }}>Page Views</span></th>
                            <th style={{width:'14%',borderBottom:'none'}}><span style={{ fontSize:'16px', 'font-weight': 'bold' }}>Ad Clicks</span></th>
                            <th style={{width:'14%',borderBottom:'none'}}><span style={{ fontSize:'16px', 'font-weight': 'bold' }}>Ad RPM</span></th>
                            <th style={{width:'14%',borderBottom:'none'}}><span style={{ fontSize:'16px', 'font-weight': 'bold' }}>Ad CPC</span></th>
                            <th style={{width:'14%',borderBottom:'none'}}><span style={{ fontSize:'16px', 'font-weight': 'bold' }}>Net To Ekank(INR)</span></th>
                            <th style={{width:'14%',borderBottom:'none'}}><span style={{ fontSize:'16px', 'font-weight': 'bold' }}>Xiaomi Revenue(INR)</span></th>

                        </tr></thead>
                        <tbody>
                            {tableData.map((data, indx) => {
                                return  <tr style={{ alignItems:'center', height:'50px', width:'100%', display:'flex',justifyContent:'space-around'}} key={indx}>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.date}</td>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.pageView}</td>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.adClicks}</td>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.rpm}</td>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.ad_cpc}</td>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.net_to_ekank}</td>
                                            <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.xiaomi_revenue}</td>
                                        </tr>
                            })}
                           
                            <tr style={{ alignItems:'center', height:'50px', width:'100%', display:'flex',justifyContent:'space-around'}} >
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>Total</span></td>
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(total.total_pageviews))}</span></td>
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(total.total_adclicks))}</span></td>
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(total.total_adrpm))}</span></td>
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(total.total_adcpc))}</span></td>
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(total.total_net_to_ekank))}</span></td>
                                <td style={{width:'14%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }}>{numberWithCommas(parseFloat(total.total_xiaomi_revenue))}</span></td>
                            </tr>
                        </tbody>
                    </table>}
                    {tab===1 && <table style={{width : '100%'}}>
                        <thead  ><tr style={{alignItems:'center', height:'60px', width:'100%', display:'flex',justifyContent:'space-around'}}>
                            <th style={{width:'18%',borderBottom:'none',}}><span style={{ fontSize:'15px','font-weight': 'bold' }}>Date</span></th>
                            <th style={{width:'18%',borderBottom:'none',}}><span style={{ fontSize:'15px','font-weight': 'bold' }}>SUM of Ad impressions</span></th>
                            <th style={{width:'18%',borderBottom:'none',}}><span style={{ fontSize:'15px','font-weight': 'bold' }}>SUM of Estimated<br/> Gross revenue ()</span></th>
                            <th style={{width:'18%',borderBottom:'none',}}><span style={{ fontSize:'15px','font-weight': 'bold' }}>SUM of Net Revenue<br/>to Ekank (@ 80%)</span></th>
                            <th style={{width:'18%',borderBottom:'none',}}><span style={{ fontSize:'15px','font-weight': 'bold' }}>SUM of Xiaomi<br/>Share (@70%)</span></th>
                            <th style={{width:'10%',borderBottom:'none'}}><span style={{ fontSize:'15px','font-weight': 'bold' }}>eCPM</span></th>
                        </tr></thead>
                        <tbody>
                            {adSenseTableData.map((data, indx) => {
                                return  <tr style={{ alignItems:'center', height:'50px', width:'100%', display:'flex',justifyContent:'space-around'}} key={indx}>
                                            <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.date}</td>
                                            <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.sum_of_ad_impression}</td>
                                            <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.sum_of_estimated_gross_revenue}</td>
                                            <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.sum_of_net_revenue_to_ekank}</td>
                                            <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.sum_of_xiaomi_share}</td>
                                            <td style={{width:'10%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}>{data.ecpm}</td>
                                        </tr>
                            })}
                            <tr style={{ alignItems:'center', height:'50px', width:'100%', display:'flex',justifyContent:'space-around'}} >
                                <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }} >Total</span></td>
                                <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }} >{numberWithCommas(parseFloat(totalAdSense.total_sum_of_ad_impression))}</span></td>
                                <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }} >{numberWithCommas(parseFloat(totalAdSense.total_sum_of_estimated_gross_revenue))}</span></td>
                                <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }} >{numberWithCommas(parseFloat(totalAdSense.total_sum_of_net_revenue_to_ekank))}</span></td>
                                <td style={{width:'18%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }} >{numberWithCommas(parseFloat(totalAdSense.total_sum_of_xiaomi_share))}</span></td>
                                <td style={{width:'10%',borderBottom:'none',display:'flex',justifyContent:'space-around'}}><span style={{ 'font-weight': 'bold' }} >{numberWithCommas(parseFloat(totalAdSense.total_ecpm))}</span></td>
                            </tr>
                        </tbody>

                        </table>}
                </div>
            </div>
        </StatsCont>
        // </React.Fragment>
    )
}

export default Samhita


